const mCRPC = {
  recommendations:{
    "1": {
        "body": {
            "en-ca": "For patients with low-burden disease without previous radiation treatment, CUA guidelines recommend the following treatment options.",
            "fr-ca": "Pour les patients atteints d'une maladie à faible ampleur sans radiothérapie préalable, les recommandations de l'AUC préconisent les options de traitement suivantes."
        },
        "treatments": [
            {
                "option": {
                    "en-ca": "ADT + apalutamide",
                    "fr-ca": "TPA + apalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 5
            },
            {
                "option": {
                    "en-ca": "ADT + enzalutamide",
                    "fr-ca": "TPA + enzalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 6
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel",
                    "fr-ca": "TPA + docétaxel"
                },
                "recommendation": {
                    "level": 2,
                    "strength": "weak"
                },
                "kt_id": 7
            },
            {
                "option": {
                    "en-ca": "Refer for radiation oncology opinion for treatment of primary",
                    "fr-ca": "Consulter un spécialiste en radio-oncologie pour le traitement des maladies primaires"
                },
                "recommendation": {
                    "level": 2,
                    "strength": "strong"
                },
                "kt_id": null,
                "kt_na": "na_1"
            },
            {
                "option": {
                    "en-ca": "ADT + abiraterone",
                    "fr-ca": "TPA + abiraterone"
                },
                "recommendation": {
                    "level": 3,
                    "strength": "weak"
                },
                "kt_id": 8
            },
            {
                "option": {
                    "en-ca": "Consider patient for clinical trial for oligometastasic disease.",
                    "fr-ca": "Envisagez un essai clinique pour les oligométastases."
                },
                "recommendation": {
                    "level": "expert",
                    "strength": "strong"
                },
                "kt_id": null,
                "kt_na": "na_2"
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel + darolutamide<up>*</up>",
                    "fr-ca": "TPA + docétaxel + darolutamide<up>*</up>"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 9
            }
        ],
        "footer": {
            "en-ca": "<p><up>* </up>In patients who can safely tolerate docetaxel and in whom docetaxel is felt to be appropriate, triplet regimen should be considered as a treatment option.</p>",
            "fr-ca": "<p><up>* </up>Chez les patients qui tolèrent bien le docétaxel et chez qui le docétaxel est considéré comme approprié, une trithérapie devrait être envisagée.</p>"
        }
    },
    "2": {
        "body": {
            "en-ca": "For patients with low-burden disease and previous radiation treatment, CUA guidelines recommend the following treatment options.",
            "fr-ca": "Pour les patients dont la maladie est de faible ampleur et qui ont déjà reçu une radiothérapie, les recommandations de l'AUC préconisent les options de traitement suivantes."
        },
        "treatments": [
            {
                "option": {
                    "en-ca": "ADT + apalutamide",
                    "fr-ca": "TPA + apalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 5
            },
            {
                "option": {
                    "en-ca": "ADT + enzalutamide",
                    "fr-ca": "TPA + enzalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 6
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel",
                    "fr-ca": "TPA + docétaxel"
                },
                "recommendation": {
                    "level": 2,
                    "strength": "weak"
                },
                "kt_id": 7
            },
            {
                "option": {
                    "en-ca": "ADT + abiraterone + prednisone",
                    "fr-ca": "TPA + abiraterone + prednisone"
                },
                "recommendation": {
                    "level": 3,
                    "strength": "weak"
                },
                "kt_id": 8
            },
            {
                "option": {
                    "en-ca": "Consider patient for clinical trial for oligometastasis",
                    "fr-ca": "Envisagez un essai clinique pour les oligométastases."
                },
                "recommendation": {
                    "level": "expert",
                    "strength": "strong"
                },
                "kt_id": null,
                "kt_na": "na_1"
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel + darolutamide<up>*</up>",
                    "fr-ca": "TPA + docétaxel + darolutamide<up>*</up>"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 9
            }
        ],
        "footer": {
            "en-ca": "<p><up>* </up>In patients who can safely tolerate docetaxel and in whom docetaxel is felt to be appropriate, triplet regimen should be considered as a treatment option.</p>",
            "fr-ca": "<p><up>* </up>Chez les patients qui tolèrent bien le docétaxel et chez qui le docétaxel est considéré comme approprié, une trithérapie devrait être envisagée.</p>"
        }
    },
    "3": {
        "body": {
            "en-ca": "For patients with high-burden disease with visceral metastases, CUA guidelines recommend the following treatment options.",
            "fr-ca": "Pour les patients présentant une maladie à forte charge de morbidité, avec des métastases viscérales, les recommandations de l'AUC préconisent les options de traitement suivantes."
        },
        "treatments": [
            {
                "option": {
                    "en-ca": "ADT + docetaxel",
                    "fr-ca": "TPA + docétaxel"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 7
            },
            {
                "option": {
                    "en-ca": "ADT + abiraterone + prednisone",
                    "fr-ca": "TPA + abiraterone + prednisone"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 8
            },
            {
                "option": {
                    "en-ca": "ADT + apalutamide",
                    "fr-ca": "TPA + apalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 5
            },
            {
                "option": {
                    "en-ca": "ADT + enzalutamide",
                    "fr-ca": "TPA + enzalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 6
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel + darolutamide<up>*</up>",
                    "fr-ca": "TPA + docétaxel + darolutamide<up>*</up>"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 9
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel + abiraterone<up>*</up>",
                    "fr-ca": "TPA + docétaxel + abiratérone<up>*</up>"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 10
            }
        ],
        "footer": {
            "en-ca": "<p><up>* </up>In patients who can safely tolerate docetaxel and in whom docetaxel is felt to be appropriate, triplet regimen should be considered as a treatment option</p>",
            "fr-ca": "<p><up>* </up>Chez les patients qui tolèrent bien le docétaxel et chez qui le docétaxel est considéré comme approprié, une trithérapie devrait être envisagée.</p>"
        }
    },
    "4": {
        "body": {
            "en-ca": "For patients with high-burden disease without visceral metastases, CUA guidelines recommend the following treatment options.",
            "fr-ca": "Pour les patients atteints d'une maladie à forte charge de morbidité sans métastases viscérales, les recommandations de l'AUC préconisent les options de traitement suivantes."
        },
        "treatments": [
            {
                "option": {
                    "en-ca": "ADT + abiraterone + prednisone",
                    "fr-ca": "TPA + abiraterone + prednisone"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 8
            },
            {
                "option": {
                    "en-ca": "ADT + apalutamide",
                    "fr-ca": "TPA + apalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 5
            },
            {
                "option": {
                    "en-ca": "ADT + enzalutamide",
                    "fr-ca": "TPA + enzalutamide"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 6
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel",
                    "fr-ca": "TPA + docétaxel"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 7
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel + darolutamide<up>*</up>",
                    "fr-ca": "TPA + docétaxel + darolutamide<up>*</up>"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 9
            },
            {
                "option": {
                    "en-ca": "ADT + docetaxel + abiraterone<up>*</up>",
                    "fr-ca": "TPA + docétaxel + abiratérone<up>*</up>"
                },
                "recommendation": {
                    "level": 1,
                    "strength": "strong"
                },
                "kt_id": 10
            }
        ],
        "footer": {
            "en-ca": "<p>* In patients who can safely tolerate docetaxel and in whom docetaxel is felt to be appropriate, triplet regimen should be considered as a treatment option</p>",
            "fr-ca": "<p>* Chez les patients qui tolèrent bien le docétaxel et chez qui le docétaxel est considéré comme approprié, une trithérapie devrait être envisagée.</p>"
        }
    }
  },
  kt:{
    "treatments": {
        "5": {
                "drugs": ["Olaparib","Denosumab"],
                "title": {
                    "en-ca": "Olaparib, Denosumab",
                    "fr-ca": "Traitement par privation androgénique (TPA) avec de l’apalutamide"
                },
                "start": {
                    "en-ca": [
                        "240 mg apalutamide, once daily",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Drug Access Listing: Advanced Prostate Cancer</a>"
                    ],
                    "fr-ca": [
                        "240 mg d'apalutamide, une fois par jour",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Liste d'accès aux médicaments : Cancer avancé de la prostate</a>"
                    ]
                },
                "monitoring": {
                    "en-ca": [
                        "PSA monitoring every 3 months, and consider testosterone measurement.",
                        "Imaging at baseline, at PSA nadir (or best response) and annually thereafter unless PSA progression or symptomatic progression.",
                        "Side effect monitoring per Product Monograph (every 2 weeks for the first 3 months, then monthly): risk of falls, TSH, ECG and bone mineral density.",
                        "Side effect monitoring per expert opinion (monthly for first 3 months, then every 3 months): blood pressure, edema, weight, fatigue, falls, cognitive decline, skin rash, seizures, liver function, potassium, TSH, HbA1c, lipid profile, ECG, bone mineral density."
                    ],
                    "fr-ca": [
                        "Faire un suivi de l'APS aux 3 mois et envisager de mesurer la testostérone.",
                        "Radiographie au départ, au nadir du dépistage de l’antigène prostatique spécifique (APS) (ou à la meilleure réaction) et ensuite chaque année, à moins que l’APS ne progresse ou qu'il y ait une progression symptomatique.",
                        "Suivi des effets secondaires, suivant la monographie du produit (toutes les 2 semaines pendant les 3 premiers mois, puis tous les mois) : risque de chute, TSH, ECG et densité minérale osseuse.",
                        "Suivi des effets secondaires selon l'avis des spécialistes (tous les mois pendant les 3 premiers mois, puis tous les 3 mois) : tension artérielle, œdèmes, poids, fatigue, chutes, déclin cognitif, éruptions cutanées, convulsions, fonction hépatique, potassium, TSH, HbA1c, profil lipidique, ECG, densité minérale osseuse."
                    ]
                },
                "progression": {
                    "en-ca": [
                        "PSA progression: ≥25% PSA increase and ≥2 ng/mL above nadir, confirm by second value ≥3 weeks later.",
                        "If available, urologist to consider ordering somatic genetic testing at progression, and referral to medical oncologist."
                    ],
                    "fr-ca": [
                        "Progression de l’APS : augmentation de l’APS de ≥25 % et de ≥2 ng/mL au-dessus du nadir, confirmée par une deuxième valeur ≥3 semaines plus tard.",
                        "Dans la mesure du possible, l'urologue pourrait envisager de prescrire un test génétique somatique au stade de la progression, et orienter le patient vers un oncologue médical."
                    ]
                },
                "qol": {
                    "en-ca": [
                        "When treated with ADT and apalutamide, quality of life and patient-reported outcomes did not worsen vs ADT alone after 44 months follow up."
                    ],
                    "fr-ca": [
                        "Lorsqu'ils ont été traités par un traitement par privation androgénique (TPA) et par de l'apalutamide, la qualité de vie et les résultats de santé rapportés par les patients, n’ont pas détériorés par rapport au TPA administré seul pendant 44 mois de suivi."
                    ]
                },
                "links": [
                    "CUA Notice of Potential DDI",
                    "CUA ADT Monitoring and Management of Adverse Events",
                    "[5] Product Monograph - apalutamide"
                ],
                "references": {
                    "en-ca": [
                        "Agarwal, N., McQuarrie, K., Bjartell, A., Chowdhury, S., de Santana Gomes, A. J. P., Chung, B. H., ... & Chi, K. N. (2019). Health-related quality of life after apalutamide treatment in patients with metastatic castration-sensitive prostate cancer (TITAN): a randomised, placebo-controlled, phase 3 study. <i>The Lancet Oncology</i>, 20(11), 1518-1530.",
                        "Agarwal, N., Chowdhury, S., Bjartell, A., Chung, B. H., Pereira de Santana Gomes, A. J., Given, R., ... & TITAN investigators. (2021). Health-related quality of life (HRQoL) and patient-reported outcomes at final analysis of the TITAN study of apalutamide (APA) versus placebo (PBO) in patients (pts) with metastatic castration-sensitive prostate cancer (mCSPC) receiving androgen deprivation therapy (ADT)."
                    ],
                    "fr-ca": [
                        "Agarwal, N., McQuarrie, K., Bjartell, A., Chowdhury, S., de Santana Gomes, A. J. P., Chung, B. H., ... & Chi, K. N. (2019). Amélioration de la qualité de vie liée à la santé après un traitement avec de l’apalutamide chez des patients atteints d'un Cancer de la prostate métastatique sensible à la castration (TITAN) : étude randomisée, contrôlée par placébo, de phase 3. <i>The Lancet Oncology</i>, 20(11), 1518-1530.",
                        "Agarwal, N., Chowdhury, S., Bjartell, A., Chung, B. H., Pereira de Santana Gomes, A. J., Given, R., ... & enquêteurs TITAN. (2021). Les données sur la qualité de vie liée à la santé (QVLS) et les résultats rapportés par les patients lors de l'analyse finale de l'étude TITAN sur l'apalutamide (APA) comparé au placébo (PBO) chez les patients (pts) atteints d'un Cancer de la prostate métastatique sensible à la castration (CPMSC) sous traitement par privation androgénique (TPA)."
                    ]
                }
        },
        "6": {
                "drugs": ["Abiraterone","Denosumab"],
                "title": {
                    "en-ca": "Abiraterone, Denosumab",
                    "fr-ca": "Traitement par privation androgénique (TPA) avec de l’enzalutamide"
                },
                "start": {
                    "en-ca": [
                        "160 mg enzalutamide, once daily",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Drug Access Listing: Advanced Prostate Cancer</a>"
                    ],
                    "fr-ca": [
                        "160 mg d'enzalutamide, une fois par jour",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Liste d'accès aux médicaments : Cancer avancé de la prostate</a>"
                    ]
                },
                "monitoring": {
                    "en-ca": [
                        "PSA monitoring every 3 months, and consider testosterone measurement.",
                        "Imaging at baseline, at PSA nadir (or best response) and annually thereafter unless PSA progression or symptomatic progression.",
                        "Side effect monitoring per Product Monograph (at baseline and then per routine practice): blood pressure, electrolytes, ECG.",
                        "Side effect monitoring per expert opinion (before initiation with ADT, then as clinically indicated): blood pressure, edema, weight, fatigue, falls, cognitive decline, seizures, liver function, TSH, HbA1c, lipid profile, ECG, bone mineral density."
                    ],
                    "fr-ca": [
                        "Faire un suivi de l'APS aux 3 mois et envisager de mesurer la testostérone.",
                        "Radiographie au départ, au nadir du dépistage de l’antigène prostatique spécifique (APS) (ou à la meilleure réaction) et ensuite chaque année, à moins que l’APS ne progresse ou qu'il y ait une progression symptomatique.",
                        "Suivi des effets secondaires selon la monographie du produit (au départ et ensuite conformément à la pratique courante) : tension artérielle, électrolytes, ECG.",
                        "Suivi des effets secondaires selon l'avis d'experts (avant le début du traitement par privation androgénique (TPA), puis selon les indications cliniques) : tension artérielle, œdèmes, poids, fatigue, chutes, affaiblissement des fonctions cognitives, crises d'épilepsie, fonction hépatique, TSH, HbA1c, profils lipidiques, ECG, densité minérale osseuse."
                    ]
                },
                "progression": {
                    "en-ca": [
                        "PSA progression: ≥25% PSA increase and ≥2 ng/mL above nadir, confirm by second value ≥3 weeks later.",
                        "If available, urologist to consider ordering somatic genetic testing at progression, and referral to medical oncologist."
                    ],
                    "fr-ca": [
                        "Progression de l’APS : augmentation de l’APS de ≥25 % et de ≥2 ng/mL au-dessus du nadir, confirmée par une deuxième valeur ≥3 semaines plus tard.",
                        "Dans la mesure du possible, l'urologue pourrait envisager de prescrire un test génétique somatique au stade de la progression, et orienter le patient vers un oncologue médical."
                    ]
                },
                "qol": {
                    "en-ca": [
                        "When treated with ADT and enzalutamide, quality of life and patient-reported outcomes did not worsen vs ADT alone after 36 months follow up."
                    ],
                    "fr-ca": [
                        "Pour les patients ayant reçu un traitement par privation androgénique (TPA) et de l'enzalutamide, la qualité de vie et les résultats rapportés par les patients sont restés inchangés par rapport au traitement TPA seul au cours des 36 mois de suivi."
                    ]
                },
                "links": [
                    "CUA Notice of Potential DDI",
                    "CUA ADT Monitoring and Management of Adverse Events",
                    "[6] Product Monograph - enzalutamide"
                ],
                "references": {
                    "en-ca": [
                        "Stenzl, A., Dunshee, C., De Giorgi, U., Alekseev, B., Iguchi, T., Szmulewitz, R. Z., ... & Armstrong, A. J. (2020). Effect of enzalutamide plus androgen deprivation therapy on health-related quality of life in patients with metastatic hormone-sensitive prostate cancer: an analysis of the ARCHES Randomised, placebo-controlled, phase 3 study. <i>European urology</i>, 78(4), 603-614.",
                        "Stockler, M. R., Martin, A. J., Dhillon, H., Davis, I. D., Chi, K. N., Chowdhury, S., ... & Sweeney, C. J. (2019). Health-related quality of life (HRQL) in a randomized phase III trial of enzalutamide with standard first-line therapy for metastatic, hormone-sensitive prostate cancer (mHSPC): ENZAMET (ANZUP 1304), an ANZUP-led, international, co-operative group trial. <i>Annals of Oncology</i>, 30, v886-v887."
                    ],
                    "fr-ca": [
                        "Stenzl, A., Dunshee, C., De Giorgi, U., Alekseev, B., Iguchi, T., Szmulewitz, R. Z., ... & Armstrong, A. J. (2020). Effets de l'enzalutamide, associé à une thérapie de privation d'androgènes sur la qualité de vie liée à la santé chez les patients atteints d'un cancer de la prostate métastatique hormono-sensible : analyse de l'étude ARCHES, étude de phase 3 randomisée, contrôlée par placébo. <i>European urology</i>, 78(4), 603-614.",
                        "Stockler, M. R., Martin, A. J., Dhillon, H., Davis, I. D., Chi, K. N., Chowdhury, S., ... & Sweeney, C. J. (2019). Qualité de vie liée à la santé (QVLS) dans un essai randomisé de phase III à base d'enzalutamide avec un traitement standard de première ligne pour le Cancer de la prostate métastatique et hormono-sensible (CPMHS) : ENZAMET (ANZUP 1304), un essai de groupe coopératif international dirigé par ANZUP. <i>Annals of Oncology</i>, 30, v886-v887."
                    ]
                }
        },
        "7": {
                "drugs": ["Olaparib"],
                "title": {
                    "en-ca": "Olaparib",
                    "fr-ca": "Traitement par privation androgénique (TPA) avec du docétaxel"
                },
                "start": {
                    "en-ca": [
                        "75 mg/m<sup>2</sup> docetaxel, every 3 weeks for 6 cycles",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Drug Access Listing: Advanced Prostate Cancer</a>"
                    ],
                    "fr-ca": [
                        "75 mg/m<sup>2</sup> de docétaxel, toutes les 3 semaines pendant 6 cycles",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Liste d'accès aux médicaments : Cancer avancé de la prostate</a>"
                    ]
                },
                "monitoring": {
                    "en-ca": [
                        "Follow-up every 3 weeks and monitor for:",
                        [
                            "Neutropenia.",
                            "Liver toxicity.",
                            "PSA, and consider testosterone measurement.",
                            "Complete blood count baseline and before each cycle.",
                            "Liver function tests baseline and before each cycle.",
                            "Clinical toxicity assessment at each visit.",
                            "Imaging at baseline, at PSA nadir (or best response) and annually thereafter unless PSA progression or symptomatic progression.",
                            "Side effect monitoring at each visit."
                        ]
                    ],
                    "fr-ca": [
                        "Faites un suivi toutes les 3 semaines et surveillez :",
                        [
                            "Neutropénie.",
                            "Toxicité hépatique.",
                            "APS et envisager la mesure de la testostérone.",
                            "Compléter la numération globulaire de base et avant chaque cycle.",
                            "Tests de la fonction hépatique au départ et avant chaque cycle.",
                            "Évaluation de la toxicité clinique à chaque visite.",
                            "Imagerie au départ, au nadir du APS (ou meilleure réponse) et annuellement par la suite sauf progression d’APS ou progression symptomatique.",
                            "Suivi des effets secondaires à chaque visite."
                        ]
                    ]
                },
                "progression": {
                    "en-ca": [
                        "PSA progression: ≥25% PSA increase and ≥2 ng/mL above nadir, confirm by second value ≥3 weeks later.",
                        "If available, urologist to consider ordering somatic genetic testing at progression, and referral to medical oncologist."
                    ],
                    "fr-ca": [
                        "Progression de l’APS : augmentation de l’APS de ≥25 % et de ≥2 ng/mL au-dessus du nadir, confirmée par une deuxième valeur ≥3 semaines plus tard.",
                        "Dans la mesure du possible, l'urologue pourrait envisager de prescrire un test génétique somatique au stade de la progression et orienter le patient vers un oncologue médical."
                    ]
                },
                "qol": {
                    "en-ca": [
                        "When treated with docetaxel and ADT, quality of life was reported to worsen after 3 months follow up compared to ADT alone. Despite this initial decrease, quality of life with treatment with docetaxel and ADT was better than ADT alone after 12 months of treatment, and up to 36 months follow up. However, differences did not exceed the minimal clinically important difference at any time point."
                    ],
                    "fr-ca": [
                        "Les patients traités par docétaxel et avec le traitement par privation androgénique (TPA), ont rapporté que leur qualité de vie s'était détériorée après 3 mois de suivi, par rapport au traitement par privation androgénique seul. Malgré cette diminution initiale, la qualité de vie avec le traitement par docétaxel et le traitement par privation androgénique (TPA) était meilleure que celle avec le TPA seul après 12 mois de traitement, et ce jusqu'à 36 mois de suivi. Cependant, les différences n'ont à aucun moment dépassé la différence minimale cliniquement importante."
                    ]
                },
                "links": [
                    "CUA ADT Monitoring and Management of Adverse Events",
                    "[7] Product Monograph - docetaxel"
                ],
                "references": {
                    "en-ca": [
                        "Morgans, A. K., Chen, Y. H., Sweeney, C. J., Jarrard, D. F., Plimack, E. R., Gartrell, B. A., ... & Patrick-Miller, L. J. (2018). Quality of life during treatment with chemohormonal therapy: analysis of E3805 chemohormonal androgen ablation randomized trial in prostate cancer. <i>Journal of Clinical Oncology</i>, 36(11), 1088."
                    ],
                    "fr-ca": [
                        "Morgans, A. K., Chen, Y. H., Sweeney, C. J., Jarrard, D. F., Plimack, E. R., Gartrell, B. A., ... & Patrick-Miller, L. J. (2018). Qualité de vie pendant le traitement par chimio-hormonothérapie : analyse de l'essai randomisé E3805 sur la suppression androgénique dans le cancer de la prostate. <i>Journal of Clinical Oncology</i>, 36(11), 1088."
                    ]
                }
        },
        "8": {
                "drugs": ["Denosumab"],
                "title": {
                    "en-ca": "Denosumab",
                    "fr-ca": "Traitement par privation androgénique (TPA) avec de l’abiratérone et prednisone"
                },
                "start": {
                    "en-ca": [
                        "1000 mg abiraterone plus 5 mg of prednisone, daily",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Drug Access Listing: Advanced Prostate Cancer</a>"
                    ],
                    "fr-ca": [
                        "1000 mg d'abiratérone plus 5 mg de prednisone, quotidiennement",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Liste d'accès aux médicaments : Cancer avancé de la prostate</a>"
                    ]
                },
                "monitoring": {
                    "en-ca": [
                        "PSA monitoring every 3 months, and consider testosterone measurement.",
                        "Imaging at baseline, at PSA nadir (or best response), and annually thereafter unless PSA progression or symptomatic progression.",
                        "Side effect monitoring per Product Monograph",
                        [
                            "Monthly: blood pressure, edema, electrolytes",
                            "Every 2 weeks for the first 3 months, then monthly: liver function tests"
                        ],
                        "Side effect monitoring per expert opinion (every 2 weeks for the first 3 months, then every 3 months): blood pressure, edema, weight, fatigue, liver function, potassium, HbA1c, lipid profile, bone mineral density."
                    ],
                    "fr-ca": [
                        "Faire un suivi de l'APS aux 3 mois et envisager de mesurer la testostérone.",
                        "Imagerie au départ, au nadir PSA (ou meilleure réponse), puis chaque année, sauf en cas de progression de l'APS ou de progression symptomatique.",
                        "Surveillance des effets secondaires selon la monographie du produit",
                        [
                            "Mensuel : tension artérielle, œdème, électrolytes",
                            "Toutes les 2 semaines pendant les 3 premiers mois, puis tous les mois : tests de la fonction hépatique"
                        ],
                        "Surveillance des effets secondaires selon l'avis d'experts (tous les 2 mois pendant les premiers 3 mois, puis tous les 3 mois) : tension artérielle, œdèmes, poids, fatigue, fonction hépatique, potassium, HbA1c, profil lipidique, densité minérale osseuse."
                    ]
                },
                "progression": {
                    "en-ca": [
                        "PSA progression: ≥25% PSA increase and ≥2 ng/mL above nadir, confirm by second value ≥3 weeks later.",
                        "If available, urologist to consider ordering somatic genetic testing at progression, and referral to medical oncologist."
                    ],
                    "fr-ca": [
                        "Progression de l’APS : augmentation de l’APS de ≥25 % et de ≥2 ng/mL au-dessus du nadir, confirmée par une deuxième valeur ≥3 semaines plus tard.",
                        "Dans la mesure du possible, l'urologue pourrait envisager de prescrire un test génétique somatique au stade de la progression, et orienter le patient vers un oncologue médical."
                    ]
                },
                "qol": {
                    "en-ca": [
                        "When treated with ADT and abiraterone acetate plus prednisone, patients with newly diagnosed, high-burden disease improved overall health-related quality of life at 30.9 months follow-up."
                    ],
                    "fr-ca": [
                        "Les patients atteints d'une maladie nouvellement diagnostiquée et à forte charge de morbidité, ont reporté que leur santé et qualité de vie globale a été améliorée sur une période de 30 mois après avoir été traités par privation androgénique (TPA), puis par de l'acétate d'abiratérone et de la prédnisone."
                    ]
                },
                "links": [
                    "CUA Notice of Potential DDI",
                    "CUA ADT Monitoring and Management of Adverse Events",
                    "[8] Product Monograph - abiraterone"
                ],
                "references": {
                    "en-ca": [
                        "Chi, K. N., Protheroe, A., Rodríguez-Antolín, A., Facchini, G., Suttman, H., Matsubara, N., ... & Fizazi, K. (2018). Patient-reported outcomes following abiraterone acetate plus prednisone added to androgen deprivation therapy in patients with newly diagnosed metastatic castration-naive prostate cancer (LATITUDE): an international, randomised phase 3 trial. <i>The Lancet Oncology</i>, 19(2), 194-206."
                    ],
                    "fr-ca": [
                        "Chi, K. N., Protheroe, A., Rodríguez-Antolín, A., Facchini, G., Suttman, H., Matsubara, N., ... & Fizazi, K. (2018). Les résultats rapportés par les patients suivant des traitements avec de l'acétate d’abiratérone et la prédnisone, ajoutés à une thérapie de privation d'androgènes, chez les patients ayant reçu un diagnostic récent d'un Cancer de la prostate métastatique sensible à la castration (CPMSC) (LATITUDE) : un essai international randomisé de phase 3. <i>The Lancet Oncology</i>, 19(2), 194-206."
                    ]
                }
        },
        "9": {
                "drugs": ["Alendronate","Apalutamide"],
                "title": {
                    "en-ca": "Alendronate, Apalutamide",
                    "fr-ca": "Traitement par privation androgénique (TPA) avec docétaxel et darolutamide"
                },
                "start": {
                    "en-ca": [
                        "75 mg/m<sup>2</sup> docetaxel, every 3 weeks for 6 cycles",
                        "600 mg darolutamide, twice daily",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Drug Access Listing: Advanced Prostate Cancer</a>"
                    ],
                    "fr-ca": [
                        "75 mg/m<sup>2</sup> de docétaxel, toutes les trois semaines, pendant 6 cycles",
                        "600 mg de darolutamide, deux fois par jour",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Liste d'accès aux médicaments : Cancer avancé de la prostate</a>"
                    ]
                },
                "monitoring": {
                    "en-ca": [
                        "Follow-up every 3 weeks and monitor for:",
                        [
                            "Neutropenia.",
                            "Liver toxicity.",
                            "PSA, and consider testosterone measurement.",
                            "Complete blood count baseline and before each docetaxel cycle.",
                            "Liver function tests baseline and before each cycle",
                            "Clinical toxicity assessment at each visit",
                            "Imaging at baseline, at PSA nadir (or best response) and annually thereafter unless PSA progression or symptomatic progression.",
                            "Side effect monitoring at each visit."
                        ],
                        "Monitor per routine clinical practice:",
                        [
                            "Ischemic heart disease",
                            "ALT and AST",
                            "If concurrently on CYP3A4, P-gp or BCRP inhibitors, monitor closely for darolutamide related adverse events",
                            "If concurrently on OATP1B1, OATP1B3, or BCRP substrates and no suitable therapeutic alternative is available, follow the monitoring advice on the aforementioned product monographs"
                        ]
                    ],
                    "fr-ca": [
                        "Faire un suivi toutes les trois semaines et surveiller : ",
                        [
                            "Neutropénie.",
                            "Toxicité hépatique.",
                            "APS et envisager la mesure de la testostérone.",
                            "Numération globulaire de base et avant chaque cycle.",
                            "Tests de la fonction hépatique au départ et avant chaque cycle.",
                            "Évaluation de la toxicité clinique à chaque visite.",
                            "Imagerie au départ, au nadir de l’APS (ou meilleure réponse) et annuellement par la suite sauf progression d’APS ou progression symptomatique.",
                            "Suivi des effets secondaires à chaque visite."
                        ],
                        "Surveiller selon les pratiques cliniques habituelles :",
                        [
                            "Maladie cardiaque ischémique",
                            "ALAT et ASAT",
                            "Si prise concomitante d’inhibiteurs du CYP3A4, de la P-gp ou de la BCRP, surveillez de près les effets indésirables liés au darolutamide",
                            "Si prise concomitante de substrats d’OATP1B1, d’OATP1B3 ou de la BCRP et qu’aucune autre option thérapeutique ne convient, suivez les recommandations de suivi dans la monographie du produit mentionné"
                        ]
                    ]
                },
                "progression": {
                    "en-ca": [
                        "PSA progression: ≥25% PSA increase and ≥2 ng/mL above nadir, confirm by second value ≥3 weeks later.",
                        "If available, urologist to consider ordering somatic genetic testing at progression, and referral to medical oncologist."
                    ],
                    "fr-ca": [
                        "Progression de l’APS : augmentation de l’APS de ≥25 % et de ≥2 ng/mL au-dessus du nadir, confirmée par une deuxième valeur ≥3 semaines plus tard.",
                        "Dans la mesure du possible, l'urologue pourrait envisager de prescrire un test génétique somatique au stade de la progression et orienter le patient vers un oncologue médical."
                    ]
                },
                "qol": {
                    "en-ca": [
                        "In the ARASENS trial, quality of life was maintained over time. Darolutamide had no adverse impact on quality of life, including in patients with poor prognosis."
                    ],
                    "fr-ca": [
                        "Dans l’essai ARASENS, la qualité de vie était maintenue dans le temps. Le darolutamide n’a eu aucun effet négatif sur la qualité de vie, notamment chez les patients présentant un mauvais pronostic."
                    ]
                },
                "links": [
                    "CUA Notice of Potential DDI",
                    "CUA ADT Monitoring and Management of Adverse Events",
                    "Product Monograph - docetaxel",
                    "[9] Product Monograph - darolutamide"
                ],
                "references": {
                    "en-ca": [
                        "Smith, M.R., Hussain, M., Saad, F., Fizazi, K., Sternberg, C.N., Crawford, E.D., Kopyltson, E., ... & ARASENS investigators. (2022).",
                        "Darolutamide and Survival in Metastatic, Hormone-Sensitive Prostate Cancer. The New England Journal of Medicine, 386(12), 1132-42."
                    ],
                    "fr-ca": [
                        "Smith, M.R., Hussain, M., Saad, F., Fizazi, K., Sternberg, C.N., Crawford, E.D., Kopyltson, E., ... et les chercheurs de l’étude ARASENS. (2022).",
                        "Darolutamide and Survival in Metastatic, Hormone-Sensitive Prostate Cancer. The New England Journal of Medicine, 386(12), 1132-42."
                    ]
                }
        },
        "10": {
                "drugs": ["Abiraterone","Darolutamide"],
                "title": {
                    "en-ca": "Abiraterone, Darolutamide",
                    "fr-ca": "Traitement par privation androgénique (TPA) avec docétaxel et abiratérone"
                },
                "start": {
                    "en-ca": [
                        "75 mg/m<sup>2</sup> docetaxel, every 3 weeks for 6 cycles",
                        "1000 mg abiraterone daily plus 10 mg prednisone, daily",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Drug Access Listing: Advanced Prostate Cancer</a>"
                    ],
                    "fr-ca": [
                        "75 mg/m<sup>2</sup> de docétaxel, toutes les trois semaines, pendant 6 cycles",
                        "1000 mg d’abiratérone quotidiennement et 10 mg de prednisone quotidiennement",
                        "<a href='https://www.cua.org/sites/default/files/Banner-Images/CUA%20Adv%20Prostate%20Ca%20Drug%20Access%20List%20V-01-MAR-2022.pdf' target='_blank'>Liste d'accès aux médicaments : Cancer avancé de la prostate</a>"
                    ]
                },
                "monitoring": {
                    "en-ca": [
                        "PSA monitoring every 3 months, and consider testosterone measurement.",
                        "Imaging at baseline, at PSA nadir (or best response), and annually thereafter unless PSA progression or symptomatic progression.",
                        "Side effect monitoring per Product Monograph:",
                        [
                            "Monthly: blood pressure, edema, electrolytes",
                            "Every 2 weeks for the first 3 months, then monthly: liver function tests"
                        ],
                        "Side effect monitoring per expert opinion (every 2 weeks for the first 3 months, then every 3 months): blood pressure, edema, weight, fatigue, liver function, potassium, HbA1c, lipid profile, bone mineral density.",
                        "Follow-up every 3 weeks and monitor for:",
                        [
                            "Neutropenia.",
                            "Liver toxicity.",
                            "PSA, and consider testosterone measurement.",
                            "Complete blood count baseline and before each cycle.",
                            "Liver function tests baseline and before each docetaxel cycle.",
                            "Clinical toxicity assessment at each visit.",
                            "Side effect monitoring at each visit."
                        ]
                    ],
                    "fr-ca": [
                        "Faire un suivi de l'APS aux 3 mois et envisager de mesurer la testostérone.",
                        "Imagerie au départ, au nadir de l’APS (ou meilleure réponse), puis chaque année, sauf en cas de progression de l'APS ou de progression symptomatique.",
                        "Surveillance des effets secondaires selon la monographie du produit",
                        [
                            "Mensuel : tension artérielle, œdèmes, électrolytes",
                            "Toutes les 2 semaines pendant les 3 premiers mois, puis tous les mois : tests de la fonction hépatique"
                        ],
                        "Surveillance des effets secondaires selon l'avis des spécialistes (tous les 2 mois pendant les 3 premiers mois, puis tous les 3 mois) : tension artérielle, œdèmes, poids, fatigue, fonction hépatique, potassium, HbA1c, profil lipidique, densité minérale osseuse.",
                        "Faire un suivi toutes les trois semaines et surveiller : ",
                        [
                            "Neutropénie.",
                            "Toxicité hépatique.",
                            "APS et envisager la mesure de la testostérone.",
                            "Numération globulaire de base et avant chaque cycle.",
                            "Tests de la fonction hépatique au départ et avant chaque cycle.",
                            "Évaluation de la toxicité clinique à chaque visite.",
                            "Suivi des effets secondaires à chaque visite."
                        ]
                    ]
                },
                "progression": {
                    "en-ca": [
                        "PSA progression: ≥25% PSA increase and ≥2 ng/mL above nadir, confirm by second value ≥3 weeks later.",
                        "If available, urologist to consider ordering somatic genetic testing at progression, and referral to medical oncologist."
                    ],
                    "fr-ca": [
                        "Progression de l’APS : augmentation de l’APS de ≥25 % et de ≥2 ng/mL au-dessus du nadir, confirmée par une deuxième valeur ≥3 semaines plus tard.",
                        "Dans la mesure du possible, l'urologue pourrait envisager de prescrire un test génétique somatique au stade de la progression et orienter le patient vers un oncologue médical."
                    ]
                },
                "qol": {
                    "en-ca": [
                        "Better understanding of the impact of additional toxicity will be revealed upon publication of the formal quality of life analysis. Oncological benefits of triplet therapy may outweigh the adverse effect of additional toxicity."
                    ],
                    "fr-ca": [
                        "La publication de l’analyse officielle de la qualité de vie permettra une meilleure compréhension des effets de la toxicité additionnelle. Les avantages oncologiques de la trithérapie pourraient surpasser les effets négatifs de cette toxicité additionnelle."
                    ]
                },
                "links": [
                    "CUA Notice of Potential DDI",
                    "CUA ADT Monitoring and Management of Adverse Events",
                    "Product Monograph - docetaxel",
                    "[10] Product Monograph - abiraterone"
                ],
                "references": {
                    "en-ca": [
                        "Fizazi, K., Foulon, S., Carles, J., Roubaud, G., McDermott, R., Flechon, A., … & PEACE-1 Investigators. (2022). Abiraterone plus prednisone added to androgen deprivation therapy and docetaxel in de novo metastatic castration-sensitive prostate cancer (PEACE-1): a multicentre, open-label, randomised, phase 3 study with a 2 × 2 factorial design. The Lancet, 399(10336): 1695-1707."
                    ],
                    "fr-ca": [
                        "Fizazi, K., Foulon, S., Carles, J., Roubaud, G., McDermott, R., Flechon, A.,... et les chercheurs de l’essai PEACE-1. (2022). Abiraterone plus prednisone added to androgen deprivation therapy and docetaxel in de novo metastatic castration-sensitive prostate cancer (PEACE-1): a multicentre, open-label, randomised, phase 3 study with a 2 × 2 factorial design. The Lancet, 399(10336): 1695-1707."
                    ]
                }
        },
    },
    "links": {
        "CUA Notice of Potential DDI": {
            "en-ca": {
                "text": "CUA Notice of Potential DDI",
                "link": "https://www.cua.org/sites/default/files/Flipbooks/CPD/DDI/mobile/index.html"
            },
            "fr-ca": {
                "text": "Avis de l'AUC sur les interactions médicamenteuses potentielles:",
                "link": "https://www.cua.org/sites/default/files/Flipbooks/CPD/DDI/mobile/index.html"
            }
        },
        "CUA ADT Monitoring and Management of Adverse Events": {
            "en-ca": {
                "text": "CUA ADT Monitoring and Management of Adverse Events",
                "link": "https://www.cua.org/sites/default/files/Flipbooks/CPD/ADTGPtool/mobile/index.html"
            },
            "fr-ca": {
                "text": "l'AUC Suivi du TPA et prise en charge des évènements indésirables",
                "link": "https://www.cua.org/sites/default/files/Professional-Development/ADT%20GP%20Summary%20RVTOL%20FR%2002DK.pdf"
            }
        },
        "[5] Product Monograph - apalutamide": {
            "en-ca": {
                "text": "Product Monograph",
                "link": "https://www.janssen.com/canada/sites/www_janssen_com_canada/files/prod_files/live/erleada_cpm.pdf"
            },
            "fr-ca": {
                "text": "Monographie du produit",
                "link": "https://www.janssen.com/canada/sites/www_janssen_com_canada/files/prod_files/live/erleada_cpmf.pdf"
            }
        },
        "[6] Product Monograph - enzalutamide": {
            "en-ca": {
                "text": "Product Monograph",
                "link": "https://www.astellas.com/ca/system/files/xtandi_pm_jan_24_2022-final_en.pdf"
            },
            "fr-ca": {
                "text": "Monographie du produit",
                "link": "https://www.astellas.com/ca/system/files/xtandi_pm_fr_0.pdf"
            }
        },
        "[7] Product Monograph - docetaxel": {
            "en-ca": {
                "text": "Product Monograph",
                "link": "https://products.sanofi.ca/en/taxotere.pdf"
            },
            "fr-ca": {
                "text": "Monographie du produit",
                "link": "https://products.sanofi.ca/fr/taxotere.pdf"
            }
        },
        "[8] Product Monograph - abiraterone": {
            "en-ca": {
                "text": "Product Monograph",
                "link": "https://www.janssen.com/canada/sites/www_janssen_com_canada/files/prod_files/live/zytiga_cpm.pdf"
            },
            "fr-ca": {
                "text": "Monographie du produit",
                "link": "https://www.janssen.com/canada/sites/www_janssen_com_canada/files/prod_files/live/zytiga_cpmf.pdf"
            }
        },
        "[10] Product Monograph - abiraterone": {
            "en-ca": {
                "text": "Product Monograph: Abiraterone",
                "link": "https://www.janssen.com/canada/sites/www_janssen_com_canada/files/prod_files/live/zytiga_cpm.pdf"
            },
            "fr-ca": {
                "text": "Monographie du produit: abiratérone",
                "link": "https://www.janssen.com/canada/sites/www_janssen_com_canada/files/prod_files/live/zytiga_cpmf.pdf"
            }
        },
        "Product Monograph - docetaxel": {
            "en-ca": {
                "text": "Product Monograph : Docetaxel",
                "link": "https://pdf.hres.ca/dpd_pm/00056863.PDF"
            },
            "fr-ca": {
                "text": "Monographie du produit : docétaxel",
                "link": "https://pdf.hres.ca/dpd_pm/00057078.PDF"
            }
        },
        "[9] Product Monograph - darolutamide": {
            "en-ca": {
                "text": "Product Monograph : Darolutamide",
                "link": "http://www.bayer.com/sites/default/files/2020-11/nubeqa-pm-en.pdf"
            },
            "fr-ca": {
                "text": "Monographie du produit : darolutamide",
                "link": "http://www.bayer.com/sites/default/files/2020-11/nubeqa-pm-en.pdf"
            }
        }
    }
  }
}

const bc = {
    ktContent: require('./ktContent/bc.json'),
    questionSet: require('./questionSets/bc.json')
}

export default{
  mCRPC,
  bc
}