import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteBlocker: false,
    debug: false,
    localization: {
      "footer": {
        "en-ca": "Development supported by an independent grant from EMD Serono<br>All rights reserved",
        "fr-ca": "Cet outil a été créé par l’AUC et rendu possible grâce à un financement d’EMD Serono<br>Tous droits réservés"
      },
      "Decision Tool": {
        "en-ca": "Bladder Cancer Treatment Decision Support Tool",
        "fr-ca": "Outil d’aide à la décision pour le traitement du cancer de la vessie"
      },
      survey: {
        "en-ca": "Survey",
        "fr-ca": "Sondage"
      },
      recommendations: {
        "en-ca": "Recommendations",
        "fr-ca": "Recommandations"
      },
      summary: {
        "en-ca": "Summary",
        "fr-ca": "Résumé"
      },
      "answer questions": {
        "en-ca": "Please answer all questions",
        "fr-ca": "Veuillez répondre à toutes les questions"
      },
      "continue": {
        "en-ca": "Continue",
        "fr-ca": "Continuer"
      },
      "Treatment Option": {
        "en-ca": "Treatment Option",
        "fr-ca": "Option de traitement"
      },
      "Select Treatment": {
        "en-ca": "Select Treatment",
        "fr-ca": "Sélectionner le traitement"
      },
      "Select Province for DAL": {
        "en-ca": "Please select the province to display drug access information or visit our drug access listing site to see all the information.",
        "fr-ca": "Veuillez sélectionner la province pour afficher les informations sur l'accès aux médicaments ou visiter notre site de liste d'accès aux médicaments pour voir toutes les informations."
      },
      "Access the CUA DAL": {
        "en-ca": "ACCESS THE CUA DRUG LISTING PLATFORM",
        "fr-ca": "ACCÉDEZ À LA PLATEFORME DE LISTE DE MÉDICAMENTS DE L'AUC"
      },
      "reset": {
        "en-ca": "Reset",
        "fr-ca": "Réinitialiser"
      },
      "Decision Tool Summary": {
        "en-ca": "Decision Tool Summary",
        "fr-ca": "Résumé de l’outil d'aide à la décision"
      },
      "Decision Tool Summary.below": {
        "en-ca": "Below are the Canadian Urological Association treatment recommendations based on the patient information entered.",
        "fr-ca": "Vous trouverez ci-dessous les recommandations de traitement de l'Association canadienne d'urologie, formulées en fonction des renseignements fournis sur le patient."
      },
      "Drug not funded": {
        "en-ca": `<p>This drug is not currently funded in the selected province, or funding criteria is not publicly available. </p>
        <p>Please refer to our drug access listing website for information on other treatment options.</p>`,
        "fr-ca": `<p>Ce médicament n'est actuellement pas financé dans la province sélectionnée, ou les critères de financement ne sont pas accessibles au public.</p>
        <p>Veuillez consulter notre site Web de liste d’accès aux médicaments pour obtenir des informations sur d’autres options de traitement.</p>`
      },
      "Download": {
        "en-ca": "Download",
        "fr-ca": "Télécharger"
      },
      "Copy Link": {
        "en-ca": "Copy Link",
        "fr-ca": "Copier le lien"
      },
      "Funding": {
        "en-ca": "Funding",
        "fr-ca": "Financement"
      },
      "Formularies": {
        "en-ca": "Formularies",
        "fr-ca": "Formulaires"
      },
      "DAL col.Drug (Brand Name) Manufacturer": {
        "en-ca": "Drug (Brand Name) Manufacturer",
        "fr-ca": "Médicament (Marque) Fabricant"
      },
      "DAL col.Indication": {
        "en-ca": "Indication",
        "fr-ca": "Indication"
      },
      "DAL col.Strength, Route": {
        "en-ca": "Strength, Route",
        "fr-ca": "Dosage, Voie d'administration"
      },
      "DAL col.DIN": {
        "en-ca": "DIN",
        "fr-ca": "DIN"
      },
      "DAL col.Provincial Funding Eligibility Criteria": {
        "en-ca": "Provincial Funding Eligibility Criteria",
        "fr-ca": "Critères d'admissibilité au financement provincial"
      },
      "DAL col.References": {
        "en-ca": "References",
        "fr-ca": "Références"
      },
      "DAL col.Patient Assistance Programs": {
        "en-ca": "Patient Assistance Programs",
        "fr-ca": "Programmes d'assistance aux patient•e•s"
      }
    },
    controller: {
      Language: undefined,
      QuestionSet: undefined
      // Label: undefined,
      // Search: undefined,
    }
  },
  getters: {
    siteBlocker: function(state){
      return state.siteBlocker
    },
    controller(state){
      return state.controller
    },
    debug(state){
      return state.debug
    },
    Label: function(state){
      return state.controller.Label
    },
    Search: function(state){
      return state.controller.Search
    },
    Language: function(state){
      return state.controller.Language
    },
    QuestionSet: function(state){
      return state.controller.QuestionSet
    },
    localization: function(state){
      return state.localization
    }
  },
  mutations: {
    debug: function(state, payload){
      state.debug = payload
    },
    UserController: function(state, payload){
      state.controller.UserC = payload
    },
    LabelController: function(state, payload){
      state.controller.Label = payload
    },
    SearchController: function(state, payload){
      state.controller.SearchC = payload
    },
    LanguageController: function(state, payload){
      state.controller.Language = payload
    },
    QuestionSetController: function(state, payload){
      state.controller.QuestionSet = payload
    },
    siteBlocker: function(state, payload){
      state.siteBlocker = payload;
    },
  },
  actions: {
    siteBlocker: function(context, payload){
      context.commit('siteBlocker',payload)
    },      
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    LabelController: function(context, payload){
      context.commit('LabelController',payload)
    },
    SearchController: function(context, payload){
      context.commit('SearchController',payload)
    },
    LanguageController: function(context, payload){
      context.commit('LanguageController',payload)
    },
    QuestionSetController: function(context, payload){
      context.commit('QuestionSetController',payload)
    }
  },
  modules: {
  }
})
