<template>
  <v-app>
    <template v-if="allow">
      <v-container v-if="ready" style="background-color: white;max-width: 1185px;">
        <v-row>
          <v-col>
            <Appbar/>
            <v-main>
              <router-view :Controller="Controller" :language="Controller.Language.language"/>
            </v-main>    
          </v-col>
        </v-row>

        <v-row>
          <AppFooter :language="Controller.Language.language"/>
        </v-row>
    
      </v-container>
      <h1 v-else align="center">...Initializing Contollers...</h1>
      <Controllers/>
    </template>
    <Blocker v-model="allow"/>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from 'axios'
import Appbar from '@/components/Appbar'
import Controllers from './Controllers/Loader'
import Blocker from '@/components/Blocker'
import AppFooter from '@/components/AppFooter'
import LanguageSelect from '@/components/LanguageSelect'

export default {
  name: 'App',
  components:{
    Controllers,
    Appbar,
    Blocker,
    AppFooter,
    LanguageSelect
  },
  created(){
    let self = this
    Vue.prototype.sendRequest = self.sendRequest
    console.log('starting',process.env.VUE_APP_ENV)
    if(['staging','local','dev'].includes(process.env.VUE_APP_ENV)){
      this.allow = true
    }
  },
  data(){
    return {
      allow: true,
      password: null
    }
  },
  methods: {
    sendRequest: function(method='get', url, data=null, options){
      let self = this
      return new Promise((resolve, reject)=>{
        if(!options || options.remove && !options.remove.includes('CUA-API-DRUG')){
          axios.defaults.headers['CUA-API-DRUG'] = process.env.VUE_APP_RXM_API_KEY
        }else{
          delete axios.defaults.headers['CUA-API-DRUG']
        }
        let removeAuthHeader = url.search("https://s3")==0
        if(!removeAuthHeader){
          axios.defaults.headers['x-api-key'] = `${process.env.VUE_APP_API_KEY}`
        }else{
          delete axios.defaults.headers['Authorization']
        }
        // if(process.env.NODE_ENV!='development'){
        //   if(bypassUrlReplace===false){
        //     url = url.replace('/api',process.env.VUE_APP_API)
        //     console.log('bypassUrl',url)
        //   }
        // }
        if(method=='upload'){
          axios.defaults.headers['Content-Type'] = 'multipart/form-data'
          method = 'put'
        }else{
          delete axios.defaults.headers['Content-Type']
        }
        // console.log('sendRequest',{headers: axios.defaults.headers})
        axios({
          method,
          url,
          data
        }).then((response)=>{
          if(response.headers['content-type']=="text/calendar; charset=utf-8"){
            let filename = "meeting.ics"
            if(filename){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              link.setAttribute('id','download-link')
              document.body.appendChild(link);
              link.click();
              link.remove();
            }

          }
          resolve(response)
        },(error)=>{
          resolve(error)
        })
      });
    }
  },
  computed: {
    ready(){
      let Controller = this.Controller
      for(let key in Controller){
        if(Controller[key]===undefined){
          return false
        }
      }
      return true
    },
    Controller(){
      return this.$store.getters.controller
    }
  }
};
</script>

<style lang="scss">
.debug{
  color: red;
}
.defaultAccent{
  background-color: $accent_red;
}
.bc{
  background-color: $accent_orange;
}

.v-progress-linear__background{
  // background-color: $accent_orange !important;
  opacity: 1; 
  left: 33%; 
  width: 67%; 
  background-color: $accent_orange !important;
  border-color: $accent_orange !important;
}
.v-progress-linear__determinate{
  border-color: $primary !important;
  background-color: $primary !important;
}
.complete.bc{
  background-color: $accent_orange !important;
  border-color: $accent_orange !important;
}
</style>

<style scoped>
pre{
  white-space: pre-wrap;
}
#app{
  background-color: #fafafa;
}
</style>
