<template>
  <div>
    <span v-if="debug" class="debug">Treatment ID - [{{ treatment.kt_id }}]</span>
    <!-- <v-container v-if="debug">
        <v-row>
            <v-col>
                <json-viewer :value="kt.treatments[treatment.kt_id]"/>
            </v-col>
        </v-row>
    </v-container> -->
    <v-container fluid>
        <template  v-for="(heading, index) in _headings">
            <v-row v-if="heading.display" :key="`heading-${index}`">
                <v-col v-if="heading.icon" cols="1">
                    <v-img :src="heading.icon" class="heading-icon"/>
                </v-col>
                <v-col>
                    <h2 v-if="!heading.icon" class="treatment-title" v-html="heading.content"/>
                    <h3 v-if="heading.icon" class="treatment-heading" v-html="heading.text"/>
                    <component v-if="isObject(heading.content)" v-bind:is="heading.component" :language="language" :links="kt.links" :items="heading.content"/>
                </v-col>
            </v-row>    
        </template>
    </v-container>
  </div>
</template>

<script>
import icon_start from '@/assets/icons/icon_start.png'
import icon_monitoring from '@/assets/icons/icon_monitoring.png'
import icon_progressing from '@/assets/icons/icon_progressing.png'
import icon_quality from '@/assets/icons/icon_quality.png'
import icon_links from '@/assets/icons/icon_links.png'
import icon_references from '@/assets/icons/icon_references.png'
import List from './lib/list'
import Links from './lib/links'
import References from './lib/references'

export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        kt: {
            type: Object,
            required: true
        },
        treatment: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    components: {
        List,
        Links,
        References
    },
    methods: {
        isObject(item){
            return typeof item=='object'
        },
        isComponent(component){
            return component['__file']!=undefined
        }
    },
    computed: {
        _components(){
            return this
        },
        _headings(){
            let treatment = this.kt.treatments[this.treatment.kt_id]
            let language = this.language
            
            return [
                {
                    display: true,
                    content:treatment['title'][language], 
                    component: List,
                    text:'Title',
                    language
                },
                {
                    display: false,
                    content:treatment['eligibility'], 
                    component: List,
                    text: language=='fr-ca' ? 'Admissibilité' : 'Eligibility', 
                    language,
                    icon: icon_start
                },
                {
                    display: true,
                    content:treatment['start'], 
                    component: List,
                    text: language=='fr-ca' ? 'Posologie' : 'Dose', 
                    language,
                    icon: icon_start
                },
                {
                    display: this.$route.matched.find((item)=>{return item.name=='Bladder Cancer'}) ? ["1","13"].includes(this.treatment.kt_id) : false,
                    content:treatment['monitoring'], 
                    component: List,
                    text: language=='fr-ca' ? 'Surveillance ' : 'Monitoring', 
                    language,
                    icon: icon_monitoring
                },
                // {
                //      display: true,
                //     content:treatment['progression'], 
                //     component: List,
                //     text:'Progression', 
                //     language,
                //     icon: icon_progressing
                // },
                {
                    display: this.$route.matched.find((item)=>{return item.name=='Bladder Cancer'}) ? ["1","13"].includes(this.treatment.kt_id) : false,
                    content:treatment['qol'], 
                    component: List,
                    text: language=='fr-ca' ? 'Qualité de vie' : 'Quality of life', 
                    language,
                    icon: icon_quality
                },
                // { 
                //      display: true,
                //     content:treatment['links'], 
                //     component: Links,
                //     text:'External Links', 
                //     language,
                //     icon: icon_links
                // },
                {
                    display: true,
                    content:treatment['references'], 
                    component: References,
                    text: language=='fr-ca' ? 'Références' : 'References', 
                    language,
                    icon: icon_references
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.heading-icon{
    width: 35px;
}
.treatment-title{
    color: $primary;
    text-align: center;
}
.treatment-heading{
    color: $accent;
}
</style>