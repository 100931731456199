<template>
    <div>
      <ul v-for="(item, index) in items" :key="`item-${index}`">
          <li v-html="item[language]"/>
      </ul>
    </div>
  </template>
  
  <script>
  export default {
      name: "Links",
      props: {
          items: {
              type: Array,
              required: false
          },
          language: {
            type: String,
            required: true
          }
      }
  }
  </script>
  
  <style scoped>
  li{
    list-style-type: none;
    /* font-size: 10pt; */
    font-size: 0.9em;
  }
  </style>