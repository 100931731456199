<template>
    <div class="recommendations-view">
        <div v-if="ready">        
            <v-select v-if="!summary" multiple v-model="search.provinces" :items="_provinces" :label="language=='fr-ca' ? `Veuillez sélectionner la province pour afficher les informations sur l'accès aux médicaments ou visiter notre site de liste d'accès aux médicaments pour voir toutes les informations.` : `Select Province(s) to filter drug access information under treatment options.`" clearable/>
            <v-container fluid>
                <template  v-for="(trigger, ktId) in _kt">
                    <div v-if="trigger" :key="`kt-${ktId}`">
                        <template v-if="!summary">
                            <v-row>
                                <v-col>
                                    <h2 class="recommendation-title" v-html="recommendations[ktId].body[language]"/>
                                </v-col>
                            </v-row>
    
                            <v-row class="recommendation-heading">
                                <v-col :cols="config.col[1]">
                                    {{ $store.getters.localization['Treatment Option'][language]}}
                                </v-col>
    
                                <v-col :cols="config.col[2]" style="text-align: center;">
                                    <!-- CUA Recommendations -->
                                </v-col>
    
                                <v-col :cols="config.col[3]">
                                    {{ $store.getters.localization['Select Treatment'][language]}}
                                </v-col>
                            </v-row>
                        </template>
   
                        <template  v-for="(treatment, recordIndex) in recommendations[ktId].treatments">
                            <div v-if="specialCondition(ktId, treatment)" :key="`treatment-${recordIndex}`">
                                <v-row v-if="!summary" class="recommendation-row">
                                    <v-col :cols="config.col[1]">
                                        <span v-if="debug" class="debug">Recommendation ID - [{{ ktId }}]</span>
                                        <span v-html="treatment.option[language]"/>
                                    </v-col>
        
                                    <v-col :cols="config.col[2]" style="text-align: center;">
                                        <!-- Level: {{ treatment.recommendation.level }} | {{ treatment.recommendation.strength }} -->
                                    </v-col>
                                    
                                    <v-col :cols="config.col[3]">
                                        <div style="display: inline-block;" v-if="treatment.kt_id">       
                                            <span v-if="debug" class="debug">[{{ treatment.kt_id}}]</span>                             
                                            <v-checkbox v-model="treatments" :value="treatment.kt_id" dense>
                                                <template v-slot:label>
                                                    <v-icon :class="treatments.includes(treatment.kt_id) ? 'active' : 'default'">mdi-eye</v-icon>
                                                </template>
                                            </v-checkbox>
                                        </div>
                                    </v-col>
                                </v-row>                                
                                
                                <template v-if="treatments.includes(treatment.kt_id)">
                                    <v-row>
                                        <v-col>
                                            <KT :language="language" :treatment="treatment" :kt="kt" :debug="debug"></KT>
                                        </v-col>
                                    </v-row>

                                    <v-row style="margin-top: 40px;">
                                        <v-col>
                                            <span v-html="recommendations[ktId].footer[language]"/>
                                        </v-col>
                                    </v-row>
        
                                    <!-- <div class="html2pdf__page-break"/> -->
                                    <!-- <div style="page-break-after:always;"/> -->
                                    
                                    <template v-if="!busy">
                                        <template v-if="search.provinces.length>0">
                                            <div style="margin-top: 50px;" v-for="provinceId in (search.provinces.length>0 ? search.provinces : _provinces.map((record)=>{return record.value}))" :key="`drug-list-${provinceId}`">
                                                <v-row>
                                                    <v-col cols="4">
                                                        <h2 class="province-name">{{ _provinces.find((province)=>{return province.value==provinceId}).text }}</h2>
                                                    </v-col>
                                                </v-row>
                                                
            
                                                <template  v-if="_drugList[treatment.kt_id].filter((record)=>{return record.province.short_province==provinceId}).length==0">
                                                    <v-row>
                                                        <v-col>
                                                            <span v-html="$store.getters.localization['Drug not funded'][language]"/>
                                                            <v-btn depressed style="border-radius: 2px;"  class="button" target="_blank" :href="`https://www.cuadrugaccess.ca/welcome?id=10`">
                                                                {{$store.getters.localization['Access the CUA DAL'][language]}}
                                                                <v-icon style="transform: rotate(90deg);">
                                                                    mdi-triangle-small-up
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </template>
                                                
                                                <template v-else>
                                                    <v-row>
                                                        <v-col>
                                                            <div>
                                                                <h3 class="province-funding">{{$store.getters.localization['Funding'][language]}}:</h3>
                                                                <p v-html="rxm.provincesTable.find((province)=>{return province.short_province==provinceId})[language=='fr-ca' ? 'funding_fr' : 'funding_en']"/>
                                                            </div>
                                                            <div>
                                                                <h3 class="province-formularies">{{$store.getters.localization['Formularies'][language]}}:</h3>
                                                                <p v-html="rxm.provincesTable.find((province)=>{return province.short_province==provinceId})[language=='fr-ca' ? 'formularies_fr' : 'formularies_en']"/>
                                                            </div>
                                                        </v-col>
                                                    </v-row>
                                                    
                                                        
                                                    <v-row>
                                                        <v-col>
                                                            <v-data-table
                                                                :headers="_headers"
                                                                :items="_drugList[treatment.kt_id].filter((record)=>{return record.province.short_province==provinceId})"
                                                                :disable-sort="true"
                                                                hide-default-footer
                                                                disable-pagination
                                                                dense
                                                                class="drugTable pageBlock"
                                                            >
                                                                <template v-slot:body="{items}">
                                                                    <tbody v-if="items.length>0">
                                                                        <tr v-for="(item, itemIndex) in items" :key="`tr-${itemIndex}`" class="drug-row">
                                                                            <td v-for="(col, colIndex) in _headers" :key="`tr-${itemIndex}-col-${colIndex}`">
                                                                            <span v-html="item[col.value]"/>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                    <tbody v-else>
                                                                        <tr>
                                                                            <td colspan="100%" style="text-align: center;">
                                                                                <h3>No records found</h3>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </template>
                                                            </v-data-table>
                                                        </v-col>
                                                    </v-row>                                     
                                                </template>
            
            
                                                    
                                                <v-row v-if="search.provinces.length==0">
                                                    <v-col cols="8"/>
                                                    <v-col style="text-align:right;">
                                                        <!-- <v-btn depressed style="border-radius: 2px;"  class="button" target="_blank" :href="`https://www.cuadrugaccess.ca/province_details/${getProvinceInfo(provinceId).Province_ID}/${config.cancerId}`"> -->
                                                        <v-btn depressed style="border-radius: 2px;"  class="button" target="_blank" :href="`https://www.cuadrugaccess.ca/welcome?id=10`">
                                                            
                                                            {{$store.getters.localization['Access the CUA DAL'][language]}}
                                                            <v-icon style="transform: rotate(90deg);">
                                                                mdi-triangle-small-up
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-col>                                            
                                                </v-row>
                                
                                            </div>
                                        </template>
                                        <v-row v-else>
                                            <v-col style="text-align:left;">
                                                <div style="margin-bottom: 25px;">
                                                    {{$store.getters.localization['Select Province for DAL'][language]}}
                                                </div>
                                                <v-btn depressed style="border-radius: 2px;"  class="button" target="_blank" :href="`https://www.cuadrugaccess.ca/welcome?id=10`">
                                                    {{$store.getters.localization['Access the CUA DAL'][language]}}
                                                    <v-icon style="transform: rotate(90deg);">
                                                        mdi-triangle-small-up
                                                    </v-icon>
                                                </v-btn>
                                            </v-col>                                            
                                        </v-row>
                                    </template>
                                    
                                    <v-row v-else>
                                        <v-col style="text-align: center;">
                                            <v-icon class="mdi-spin">mdi-loading</v-icon>
                                        </v-col>
                                    </v-row>
        
                                    <!-- <template v-if="treatments.includes(treatment.kt_id) && recordIndex<recommendations[ktId].treatments.length-1">
                                        <div class="html2pdf__page-break"/>
                                    </template> -->
                                </template>
                                
                            </div>                        
                        </template>

                        <v-row v-if="treatments.length==0" style="margin-top: 40px;">
                            <v-col>
                                <span v-html="recommendations[ktId].footer[language]"/>
                            </v-col>
                        </v-row>
    
                    </div>
                </template>
    
    
            </v-container>        
        </div>
        <v-container v-else>
            <v-row>
                <v-col style="text-align: center;">
                    <v-icon class="mdi-spin">mdi-loading</v-icon>
                </v-col>
            </v-row>            
        </v-container>
    </div>
</template>
  
<script>
import KT from './kt'
import axios from 'axios'

export default {
    props: {
        summary: {
            type: Boolean,
            required: false,
            default: false
        },
        questionSet: {
            type: Object,
            required: true
        },
        answers: {
            type: Object,
            required: true
        },
        recommendations: {
            type: Object,
            required: true
        },
        kt: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    components: {
        KT
    },
    async created(){
        // if(process.env.NODE_ENV!='development'){
        this.apiUrl = process.env.VUE_APP_RXM_API
        // }
        this.rxm = await this.getRxMTables()

        this.applyTreatments()
        this.applyProvinces()

        await this.searchDrugs('created')

        this.ready = true
    },
    data(){
        return {
            ready: false,
            apiUrl: '/rxm',
            busy: false,
            config: {
                col: {
                    1: 8,
                    2: 0,
                    3: 2
                },
                cancerId: 10
            },
            treatments: [],
            search: {
                provinces: [],
                indications: ["UC", "aUC"],
                cancers: ["Bladder"],
                drugs: []
            },
            drugList: null,
            rxm: null,
            timeouts: {
                search: null
            }
        }
    },
    methods: {
        applyTreatments(){
            let treatmentQueries = this.$route.query.treatments
            let _recommendation = this._recommendation

            if(typeof treatmentQueries=='object' && treatmentQueries.length>0){
                for(let i=0; i<treatmentQueries.length; i++){
                    let treatmentId = treatmentQueries[i]
                    let isRecommended =  _recommendation.treatments.find((item)=>{return item.kt_id==treatmentId}) != undefined
                    if(isRecommended){
                        this.treatments.push(treatmentId)
                    }
                }
            }
            if(typeof treatmentQueries=='string'){
                let treatmentId = treatmentQueries
                let isRecommended =  _recommendation.treatments.find((item)=>{return item.kt_id==treatmentId}) != undefined
                if(isRecommended){
                    this.treatments.push(treatmentId)
                }
            }
        },
        applyProvinces(){
            let provinces = this.$route.query.provinces
            if(typeof provinces=='string'){
                this.search.provinces = [provinces]
            }else if(typeof provinces=='object'){
                this.search.provinces = provinces
            }
        },
        getProvinceInfo(provinceId){
            return this.rxm.provincesTable.find((province)=>{return province && province.short_province==provinceId})
        },
        async getRxMTables(){
            var FormData = require('form-data');
            let apiUrl = this.apiUrl
            axios.defaults.headers['CUA-API-DRUG'] = process.env.VUE_APP_RXM_API_KEY

            function getProvinces(apiUrl){
                return new Promise((resolve, reject)=>{
                    try {
                        let data = new FormData();
                        data.append('province_id', 0);
                        axios({
                            method: 'post',
                            // url: '/rxm/get_province_data',
                            url: apiUrl+'/get_province_data',
                            data
                        }).then((response)=>{
                           resolve(response.data.province_list)
                        },(error)=>{
                            reject(error)
                        })
                    } catch (error) {
                        
                    }
                })
            }
            function getIndications(apiUrl){
                return new Promise((resolve, reject)=>{
                    try {
                        let data = new FormData();
                        data.append('indication_id', 0);                        
                        axios({
                            method: 'post',
                            // url: '/rxm/get_indication_data',
                            url: apiUrl+'/get_indication_data',
                            data
                        }).then((response)=>{
                           resolve(response.data.indication_list)
                        },(error)=>{
                            reject(error)
                        })
                    } catch (error) {
                        
                    }
                })
            }
            function getDrugs(apiUrl){
                return new Promise((resolve, reject)=>{
                    try {
                        let data = new FormData();
                        data.append('drug_id', 0);                        
                        axios({
                            method: 'post',
                            // url: '/rxm/get_drugs_data',
                            url: apiUrl+'/get_drugs_data',
                            data
                        }).then((response)=>{
                           resolve(response.data.drug_data)
                        },(error)=>{
                            reject(error)
                        })
                    } catch (error) {
                        
                    }
                })
            }
            function getMappings(apiUrl){
                return new Promise((resolve, reject)=>{
                    try {
                        let data = new FormData();
                        data.append('province_id', 0);                        
                        axios({
                            method: 'get',
                            // url: '/rxm/get_mapping_data',
                            url: apiUrl+'/get_mapping_data',
                            data
                        }).then((response)=>{
                           resolve(response.data.mapping_data)
                        },(error)=>{
                            reject(error)
                        })
                    } catch (error) {
                        
                    }
                })
            }

            
            return {
                provincesTable :await getProvinces(apiUrl),
                indicationTable :await getIndications(apiUrl),
                drugTable :await getDrugs(apiUrl),
                // mappingTable :await getMappings(apiUrl),
            }
        },
        async searchDrugs(meta){
            let self = this
            async function runSearch(apiUrl, params){
                return new Promise(async (resolve, reject)=>{
                    try {
                        let response = await self.sendRequest("post",`${apiUrl}/get_search_data`, params)
                        resolve( response.data.status=='success' ? response.data.search_data : [])
                    } catch (error) {
                        // reject(error)
                        resolve([])
                    }
                    // try {
                    //     let data = new FormData();
                    //     data.append('provinces', provinces);
                    //     data.append('indications', indications);
                    //     data.append('cancers', cancers);
                    //     data.append('drugs', drugs);
                    //     axios({
                    //         method: 'post',
                    //         url: '/rxm/get_search_data',
                    //         data
                    //     }).then((response)=>{
                    //        resolve(response.data.search_data)
                    //     },(error)=>{
                    //         reject(error)
                    //     })
                    // } catch (error) {
                    //     reject(error)
                    // }
                })
            }
            this.busy = true


            
            clearTimeout(this.timeouts.search)
            self.timeouts.search = setTimeout(async() => {
                let apiUrl = self.apiUrl
                let treatments = self.treatments
                let provinces = self.search.provinces
                let indications = self.search.indications
                let cancers = self.search.cancers

                let drugMap = {}
                for (let t = 0; t < treatments.length; t++) {                
                    let treatmentId = treatments[t]
                    let treatment = self.kt.treatments[treatmentId]
                    let drugs = treatment.drugs
                    drugMap[treatmentId] = await runSearch(apiUrl, {provinces, drugs, indications, cancers})  
                }

                self.drugList = drugMap
                self.busy = false
                self.$emit('done')
            }, 800);

            
        },
        specialCondition(ktId, treatment){
            let tool = this._tool
            let answers = this.answers
            let exceptions = {
                bc: {
                    26: {
                        13: answers[1][1].value.includes("2")
                    },
                    27: {
                        13: answers[1][1].value.includes("2")
                    },
                    28: {
                        13: answers[1][1].value.includes("2")
                    },
                    29: {
                        13: answers[1][1].value.includes("2")
                    }
                }
            }

            if(exceptions[tool] && exceptions[tool][ktId]){
                let result = exceptions[tool][ktId][treatment.kt_id]
                if(result!=undefined){
                    return result
                }
            }
            return true
        }
    },
    computed:{
        debug(){
            return this.$store.getters.debug
        },
        _tool(){
            return this.$route.meta.localDb
        },
        _provinces(){
            let language = this.language
            function sortProvinces(a, b){
                const A = a.value.toUpperCase(); // ignore upper and lowercase
                const B = b.value.toUpperCase(); // ignore upper and lowercase
                if (A < B) {
                    return -1;
                }
                if (A > B) {
                    return 1;
                }

                // names must be equal
                return 0;
            }

            let provinces = []
            let provincesTable = this.rxm.provincesTable
            for(let i=0; i<provincesTable.length; i++){
                let province = provincesTable[i]
                if(province.Active==1 || province.status==1){
                    provinces.push({
                        text: language=='fr-ca' ? province.province_fr : province.Province_Name,
                        value: province.short_province //parseInt(province.Province_ID, 10)
                    })

                }
            }

            return provinces.sort(sortProvinces)
        },
        _drugList(){
            let drugList = this.drugList
            let drugTable = this.rxm.drugTable
            let indicationTable = this.rxm.indicationTable
            let provincesTable = this.rxm.provincesTable            

            let output = {}

            for(let kt_id in drugList){
                output[kt_id] = []
                let drugs = drugList[kt_id]

                for(let i=0; i<drugs.length; i++){
                    let record = drugs[i]
                    let drug = drugTable.find((drug)=>{return drug.id == record.drug_id})
                    let indication = indicationTable.find((indication)=>{return indication.id == record.indication_id})
                    let province = provincesTable.find((province)=>{return province.Province_ID == record.province_id})
                    record.drug = drug
                    record.indication = indication
                    record.province = province
                    output[kt_id].push({
                        drug: record.drug.drug_name,
                        province: record.province,
                        province_id: record.province_id,
                        indication: record.indication.indication_name,
                        strength: record.strength_route,
                        din: record.din_name,
                        funding: record.provincial_funding,
                        references: record.references,
                        assistant: record.Patient_assistant,
                    })
                }
            }
            
            return output
        },
        _headers(){
            let language = this.language
            let headers = [
                {
                    text: this.$store.getters.localization['DAL col.Drug (Brand Name) Manufacturer'][language],
                    value: 'drug',
                    align: 'center'
                },
                {
                    text: this.$store.getters.localization['DAL col.Indication'][language],
                    value: 'indication',
                    align: 'center'
                },
                {
                    text: 'Strength, Route',
                    value: 'strength',
                    align: 'center'
                },
                {
                    text: this.$store.getters.localization['DAL col.DIN'][language],
                    value: 'din',
                    align: 'center'
                },
                {
                    text: this.$store.getters.localization['DAL col.Provincial Funding Eligibility Criteria'][language],
                    value: 'funding',
                    align: 'center'
                },
                {
                    text: this.$store.getters.localization['DAL col.References'][language],
                    value: 'references',
                    align: 'center'
                },
                {
                    text: this.$store.getters.localization['DAL col.Patient Assistance Programs'][language],
                    value: 'assistant',
                    align: 'center'
                }
            ]

            return headers
        },
        _tableRecords(){
            let records = []

            return records
        },
        _kt(){
            let recommendations = this.recommendations
            let logics = this.questionSet.logics
            let logicMap = {}
            for(let i=0; i<logics.length; i++){
                let logic = logics[i]
                logicMap[logic.id] = logic
            }
            let ktMap = {}
            // let ktMap = {
            //     18: [3,4,9],
            //     2: [5,6],
            //     3: [7],
            //     4: [8]
            // }


            for(let rid in recommendations){
                let recommendation = recommendations[rid]
                ktMap[rid] = recommendation.triggers
            }

            let kt = {}
            
            
            for(let ktId in ktMap){
                kt[ktId] = false
                let ktTriggers = ktMap[ktId]
                for(let i=0; i<ktTriggers.length; i++){
                    let logicId = ktTriggers[i]
                    let result = logicMap[logicId].result

                    if(result===true){
                        kt[ktId] = true
                        break;
                    }
                }

                // for(let i=0; i<logics.length; i++){
                //     let logic = logics[i]
                //     if(ktTriggers.includes(logic.id) && logic.result){
                //         kt[ktId] = true
                //         break;
                //     }
                // }
            }


            return kt
        },
        _seletedProvinces(){
            return this.search.provinces
        },
        _recommendation(){
            let _kt=this._kt
            for(let recommendationId in _kt){
                let trigger = _kt[recommendationId]
                if(trigger){
                    return this.recommendations[recommendationId]
                }
            }
            return undefined
        }
    },
    watch: {
        treatments: {
            deep: false,
            handler: function(after, before){
                this.$emit('treatmentSelection', after)
                this.$emit('input',after)
                this.searchDrugs('watch.treatments')

            }
        },
        _seletedProvinces: {
            deep: false,
            handler: function(after, before){
                this.$emit('provinceSelection', after)
                this.searchDrugs('watch._selectedProvinces')
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.recommendation-title{
    color: $primary;
    text-align: center;
}
.recommendation-heading{
    color: $accent_secondary;
    font-weight: bold;
}
.recommendation-row{
    margin-top: 25px;
    border-bottom: 1px solid lightgray;
}
.province-name{
    font-weight: bold;
    color: white;
    background-color: $primary;
    padding: 5px 5px 5px 15px;
}
.province-funding{
    font-weight: bold;
}
.province-formularies{
    font-weight: bold;
}
.drug-row td{
    vertical-align: top;
}

.v-icon.default{
    color: rgb(128, 128, 128);
}
.v-icon.active{
    color: #00A8CB;
}

.button{
    background-color: $primary !important;    
    color: white;
}
</style>

<style lang="scss">  
tbody {
   tr:hover {
      background-color: transparent !important;
   }
}
.v-data-table-header{
    background-color: $primary !important;
}

.v-data-table-header th{
    padding-top: 15px;
    color: white !important;
    background-color: $primary !important;
    font-weight: bold;
    border-top: 0px;
    border-left: 0px;
    border-right: 1px;
    border-bottom: 0px;
    border-color: whitesmoke;
    border-style: solid;
}

tr.drug-row td{
    padding-top: 15px !important;
    border-top: 0px;
    border-left: 0px;
    border-right: 1px;
    border-bottom: 1px;
    border-color: whitesmoke;
    border-style: solid;    
}
tr.drug-row td p{
    font-size: 0.8rem;
}

.recommendations-view .v-label.theme--light{
    color: $accent_secondary;
    font-weight: bold;
}
</style>