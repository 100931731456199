import Vue from 'vue'
import VueRouter from 'vue-router'
import DecisionTool from '../views/DecisionTool.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
    redirect: {name: 'Bladder Cancer'}
  },
  {
    path: '/mCRPC',
    name: 'mCRPC',
    component: DecisionTool,
    children: [
      {
        path: '/mCRPC/:stepName',
        name: 'step_mCRPC',
        meta: {
          questionSetId: 2,
          stepName: 'step_mCRPC',
          localDb: 'mCRPC',
          accentClass: 'mCRPC'
        }
      }
    ],
    meta: {
      questionSetId: 2,
      stepName: 'step_mCRPC',
      localDb: 'mCRPC',
      accentClass: 'mCRPC'
    }
  },
  {
    path: '/bc',
    name: 'Bladder Cancer',
    component: DecisionTool,
    children: [
      {
        path: '/bc/:stepName',
        name: 'step_bc',
        meta: {
          questionSetId: 3,
          stepName: 'step_bc',
          localDb: 'bc',
          accentClass: 'bc'
        }
      }
    ],
    meta: {
      questionSetId: 3,
      stepName: 'step_bc',
      localDb: 'bc',
      accentClass: 'bc'
    }
  },
  {
    path: '/questionAdmin',
    name: 'questionAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    children: [
      {
        path: ':questionSetId',
        name: 'editQuestionSet',
        children: [
          {
            path: ':questionId',
            name: 'editQuestion'
          }
        ]
      }
    ]
  },
  {
    path: '/ktAdmin',
    name: 'ktAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ktAdmin.vue'),
    children: [
      {
        path: ':ktSetId',
        name: 'editKtSet',
        children: [
          {
            path: ':ktId',
            name: 'editKt'
          }
        ]
      }
    ]
  },
  {
    path: '/logicAdmin',
    name: 'logicAdmin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    children: [
      {
        path: ':questionSetId',
        name: 'editLogicSet',
        children: [
          {
            path: ':logicId',
            name: 'editLogic'
          }
        ]
      }
    ]
  },
  {
    path: '/dataView',
    name: 'dataView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DataView.vue'),
    children: [
      {
        path: ':toolId',
        name: 'toolView',
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
