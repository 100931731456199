<template>
<v-container>
    <v-row>
        <v-col style="text-align: center;">
            <div v-html="$store.getters.localization['footer'][language]"/>
            
        </v-col>
    </v-row>
</v-container>
</template>

<script>
export default {
    props: {
        language: {
            type: String,
            required: true
        }
    }
}
</script>

<style>

</style>